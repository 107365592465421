import './Footer.css';

const Footer = () => {
    var yearIn = new Date();
    yearIn = yearIn.getFullYear();

    return(
        <div className='Footer'>
            <p>&copy; {yearIn} stephpovey.com | all rights reserved</p>
        </div>
    )
}

export default Footer;