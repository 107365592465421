import './Story.css';
import './Steph.css';
import Cards from './Cards.js';
import BookBannerTwo from '../images/ThirdBanner.svg';
import StephOne from '../images/Steph/StephOne.png';
import StephTwo from '../images/Steph/StephTwo.png';
import StephThree from '../images/Steph/StephThree.png';
import StephFour from '../images/Steph/StephFour.png';
import StephFive from '../images/Steph/StephFive.png';
import StephSix from '../images/Steph/StephSix.png';
import StephSeven from '../images/Steph/StephSeven.png';
import StephEight from '../images/Steph/StephEight.png';
import StephNine from '../images/Steph/StephNine.png';
import StephTen from '../images/Steph/StephTen.png';
import StephEleven from '../images/Steph/StephEleven.png';
import Buy from './Buy.js';

function Story(){

    return(
        <div className='SectionThree' id="myStory">
            <div className='StoryHeader'>
                <h1>My Story</h1>
            </div>
            <div className='stephCards'>
                <Cards image={StephOne} text="I was born in 1959 in the Philippines while my father was serving in the navy."/>
                <Cards image={StephTwo} text="By the early 1960s my parents moved us to Kaysville Utah."/>
                <Cards image={StephThree} text="I started kindergarten in 1964 and began to realize I did not fit the “normal” little girl stereotype."/>
                <Cards image={StephFour} text="In 1972 Title lX was passed and the door was opened for girls to play school sports."/>
                <Cards image={StephFive} text="By the mid1970s Utah began to allow state championship tournaments for girls in high school.  "/>
                <Cards image={StephSix} text="These included tennis, volleyball, basketball, softball, and track & field."/>
                <Cards image={StephSeven} text="With great happiness and enthusiasm I played them all!"/>
                <Cards image={StephEight} text="I married Reed Povey in 1984 in Logan Utah."/>
                <Cards image={StephNine} text="Over the next 14 years we welcomed three beautiful daughters into our family. Sarah, Rachel and Hannah. They participated in the things they loved which included, singing, dancing, soccer, theater, piano, basketball and rugby."/>
                <Cards image={StephTen} text="As my family grew, so did my heart. I had no idea how much I could love."/>
                <Cards image={StephEleven} text="I have discovered that being a grandparent is just that…GRAND!"/>
            </div>
            <br />

            <div className='titleBanner bottomBannerGirl'>
                <img src={BookBannerTwo} className='titleText'/>
            </div>
            <br />

            <Buy />
            <br />



        </div>
    )
}

export default Story;