import './Steph.css';
import BookBanner from '../images/SecondBanner.svg';
import book from '../images/Steph/FirstBook.png';


function Book(){
    return(
        <div>
            <div className='titleBanner' id="myBook">
                <img src={BookBanner} className='titleText'/>
            </div>
            <div className='StephSections'>
                <div className='BookSection'>
                    <img src={book} className='bookProfile' />
                </div>
                <div className='StephSection bookText'>
                    <p>Now I am excited for others to read my book, ''You Can't Play, You're a Girl'' a memoir of my early years…a time hampered by rigid gender stereotypes and bias.</p>
                    <p>My bottom line: <br/>
                    We are all born with unique talents and abilites and should have equal opportunity to develop these gifts and feel free to expolore our innate interests.
                    </p>
                    <p>My motto: <br />
                    Be kind. Be coachable. Have courage to do hard things in life and help others along the way.
                    </p>               
                
                </div>
            </div>
        </div>
    )
}

export default Book;