import './Card.css';


const Cards = props => {
    return(
        <div className='StephCard' >
            <img src={props.image} className='cardImage' />
            <br />
            <p className='cardText'>{props.text}</p>
        </div>
    )
    }

export default Cards;