
import './App.css';
import Nav from './components/Nav.js';
import Steph from './components/Steph.js';
import Book from './components/Book.js';
import Story from './components/Story.js';
import Footer from './components/Footer.js';

function App() {
  return (
    <div>
      <Nav /> 
      <Steph />  
      <Book />   
      <Story />
      <Footer />
    </div>
  );
}

export default App;
