import './Steph.css';
import StephBanner from '../images/TopBanner.svg';
import Lottie from 'react-lottie-player';
import arrowDown from '../json/downArrow.json';
import stephProf from '../images/Steph/NewProfilePic.png';
import React, { useState } from "react";


function Steph(){
    
    var ProfileDesktop = 
    <div className='StephSections'>
        <div className='StephSection'>
            <h1>Hello, I'm Steph</h1>
            <p>I love the outdoors, working in my yard, carpentry, and playing most sports. In fact, interwoven into every fabric of my life's tapestry is the simple truth that I am a tomboy.</p>
            <p>For the past 40 years as a public educator, mother, and grandmother I have thoroughly enjoyed learning and growing with children from reading and connecting to other people's stories.</p>
        </div>
        <div className='StephSection'>
            <img src={stephProf} className='stephProfile' />
        </div>
    </div> ;
    var ProfileMobile = 
    <div className='StephSections'>
        <div className='StephSection'>
            <img src={stephProf} className='stephProfile' />
        </div>
        <div className='StephSection'>
            <h1>Hello, I'm Steph</h1>
            <p>I love the outdoors, working in my yard, carpentry, and playing most sports. In fact, interwoven into every fabric of my life's tapestry is the simple truth that I am a tomboy.</p>
            <p>For the past 40 years as a public educator, mother, and grandmother I have thoroughly enjoyed learning and growing with children from reading and connecting to other people's stories.</p>
        </div>
    </div> ;
    var widthOfPage = window.innerWidth;
    const [checkHtml, setHtml] = useState(widthOfPage);
    function checkWidth(){
        var checkSize = window.innerWidth;
        console.log(checkHtml);
        if(checkSize < 750){
            changeHtml(750);
        } else {
            changeHtml(780);
        }
    }    

    function changeHtml(size){
        setHtml(size);
    }

    window.addEventListener('resize', checkWidth);


    return(
        <div className='SectionOne'>
            <div className='titleBanner'>
                <img src={StephBanner} className='titleText'/>
            </div>
            {checkHtml <= 750 ? ProfileMobile : ProfileDesktop}
            <br />
            <div className='lottieSpot'>
            <a href="#myBook"><Lottie
                loop
                animationData={arrowDown}
                play
                style={{ width: 150, height: 150 }}
                /></a>
            </div>
        </div>
    )
}

export default Steph