import './Nav.css';
//import logo from '../images/StephLogo.svg'
import ssp from '../images/SSP_transparent_black.svg';
import ssps from '../images/SSP-logo-white.png';




function Nav() {

    
    return (
        <div className='nav-grid'>

            <div className="logoSec">
                <a href="#"><img src={ssps} className="logo-size" /></a>
            </div>

            <div className="navSec">

                <nav className="navbar navbar-expand-lg">

                    <div className="container-fluid">

                        <div className='alignNav' id="navbarNavDropdown">

                            <ul className="navbar-nav moveDown alignNav" >

                                <li className="nav-item" >
                                    <a href="#myBook" className="nav-link firstD" ><span>About the book</span></a>
                                    
                                </li>

                                <li className="nav-item" >
                                    <span><a href="#myStory" className="nav-link firstD">My Story</a></span>
                                    
                                </li>

                                <li className="nav-item" >
                                    <a href="#buyNow" className="nav-link firstD"><button className='login-B'>Buy Now</button></a>                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </div>
        </div>

    );



}

export default Nav