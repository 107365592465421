import './Buy.css';
import './Steph.css';
import secondBookImage from '../images/Steph/SecondBook.png';
import amazon from '../images/Steph/amazonNew.svg';
//import smith from '../images/Steph/WHsmith.png';
import Macauley from '../images/Steph/AustinPublish.png';
import BandN from '../images/Steph/barnesBook.png';
//import wheelers from '../images/Steph/wheelers.png';
//import Deposit from '../images/Steph/bookDepository.png'


const Buy = () => {
    
    return(
        <div className='BottomGrid' id="buyNow">
            <div className='WhereTo'>
                <h1>Where to buy</h1>
                <p>Purchase your copy from one of the these vendors:</p>
                <br />
                <br />
                <div className='buyButtons'>
                    <div className='buyLink'>
                        <a href="https://www.amazon.com/You-Cant-Play-Youre-Girl/dp/1638298246/ref=sr_1_1?crid=GUFC9KSLHKFL&keywords=you+can%27t+play+you%27re+a+girl&qid=1668462559&sprefix=Ypu+Can%27t+Play+%2Caps%2C202&sr=8-1" target="_blank"><img src={amazon} className='buyLinkImage' /></a>
                    </div>
                    <div className='buyLink'>
                    <a href="https://www.barnesandnoble.com/w/you-cant-play-youre-a-girl-stephanie-snell-povey/1142529682?ean=9781638298243" target="_blank"><img src={BandN} className='buyLinkImage' /></a>
                    </div>
                    <div className='buyLink'>
                    <a href="https://www.austinmacauley.com/us/book/you-cant-play-youre-girl" target="_blank"><img src={Macauley} className='buyLinkImage' /></a>
                    </div>                 
                </div>
            </div>
            <div className='BuySection'>
                <img src={secondBookImage} className='stephProfile' />
            </div>
        </div>
    )

}

export default Buy;